








































































































































































































































































































import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { InvoiceApPrepaymentData } from "@/models/interface/InvoiceApPrepayment.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { contactServices } from "@/services/contact.service";
import { invoiceApPrepayment } from "@/services/InvoiceApPrepayment.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { purchaseServices } from "@/services/purchase.service";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ListInvoicesPrepaymentDebitMemo extends Vue {
  currentPage = 1 as number;
  limit = 10;
  page = 0;
  dataList = [] as InvoiceApPrepaymentData[];
  totalData = 0 as number;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "invoicesPrepaymentDebitMemo" });
  downloadParam = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    supplierName: "ALL",
    invoiceDateFrom: "ALL",
    invoiceDateTo: "ALL",
    invoiceNumber: "ALL",
    poNumber: "ALL",
    returnNumber: "ALL",
    invoiceStatus: "ALL",
  };
  dataReturnNumber = [] as any;
  dataSupplierName = [] as any;
  searchData = "" as any;
  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchWarehouse",
      key: "branch",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_invoice_type"),
      dataIndex: "invoiceType",
      key: "invoiceType",
      scopedSlots: { customRender: "isNull" },
      width: 120,
    },
    {
      title: this.$t("lbl_reference_number"),
      dataIndex: "purchaseOrderNumber",
      key: "referenceNumber",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "documentNumber",
      key: "invoiceNumber",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      key: "supplierName",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      scopedSlots: { customRender: "isDate" },
      width: 200,
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
    },
  ] as ColumnTableCustom;
  formRules = {
    invoiceDateFrom: {
      decorator: ["invoiceDateFrom"],
    },
    invoiceDateTo: {
      decorator: ["invoiceDateTo"],
    },
    branch: {
      decorator: ["branch"],
    },
    supplierName: {
      decorator: ["supplierName"],
    },
    invoiceNumber: {
      decorator: ["invoiceNumber"],
    },
    poNumber: {
      decorator: ["poNumber"],
    },
    returnNumber: {
      decorator: ["returnNumber"],
    },
    invoiceStatus: {
      decorator: ["invoiceStatus"],
    },
  };
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
  dataBranch = [] as DataWarehouseBranch[];
  dataInvoiceNumber = [] as any[];
  dataPoNumber = [] as any[];

  dataInvoiceStatus = [] as any[];
  loading = {
    branch: false as boolean,
    table: false as boolean,
    find: false as boolean,
    supplierName: false as boolean,
    invoiceNumber: false as boolean,
    poNumber: false as boolean,
    returNumber: false as boolean,
    invoiceStatus: false as boolean,
  };
  searchDropdown(value, type) {
    if (value) {
      switch (type) {
        case "branch":
          this.getBranch(value);
          break;
        case "supplierName":
          this.getListSupplierName(value);
          break;
        case "invoiceNumber":
          this.getListInvoiceNumber(value);
          break;
        case "poNumber":
          this.getListPoNumber(value);
          break;
        case "returnNumber":
          this.getListreturnNumber(value);
          break;
        case "invoiceStatus":
          this.getListInvoiceStatus(value);
          break;
        default:
          break;
      }
    }
  }
  getListSupplierName(value) {
    let params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: `supplier~true_AND_active~true`,
    };
    if (value)
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    this.loading.supplierName = true;
    return contactServices
      .listContactData(params)
      .then(response => (this.dataSupplierName = response.data))
      .finally(() => (this.loading.supplierName = false));
  }
  getListInvoiceNumber(value) {
    let params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      sort: `invoiceDate:asc`,
    };
    if (value) {
      params.search = `documentNumber~*${value}*`;
    }
    this.loading.invoiceStatus = true;
    invoiceApPrepayment
      .getListInvoiceApPrepayment(params)
      .then(res => {
        this.dataInvoiceNumber = res.data;
      })
      .finally(() => (this.loading.invoiceStatus = false));
  }
  getListPoNumber(value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "invoiceDate:asc",
    } as RequestQueryParamsModel;
    if (value) params.search = `purchaseOrder.documentNumber~*${value}*`;
    this.loading.poNumber = true;
    return purchaseServices
      .getListPurchaseOrderExistPrepayment(params)
      .then(res => {
        this.dataPoNumber = res.data.filter(
          item => item && item.documentNumber
        );
      })
      .finally(() => (this.loading.poNumber = false));
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  getListOfMasterTypeInvoiceStatus() {
    let params = {
      name: "INVOICE_AP_PREPAYMENT_STATUS",
    } as RequestQueryParamsModel;
    return masterServices.listMaster(params).then(res => {
      this.dataInvoiceStatus = res;
    });
  }
  responseViewTable(response) {
    this.$router.push(
      "/account-payables/invoices/invoices-prepayment-debit-memo/edit/" +
        response.data.id
    );
  }
  findData(page): void {
    this.form.validateFields((err, value) => {
      if (!err) {
        const params: RequestQueryParamsModel = {
          page: page ? this.page : 0,
          limit: this.limit,
          sorts: "invoiceDate:asc",
        };
        for (const key in this.downloadParam) {
          if (key != "companyName") {
            this.downloadParam[key] = "ALL";
          }
        }
        if (this.dynamicSearch(value)) {
          this.searchData = this.dynamicSearch(value);
          params.search = this.dynamicSearch(value);
        } else {
          this.searchData = "";
        }
        this.getData(params);
      }
    });
  }

  getData(params) {
    this.loading.table = true;
    invoiceApPrepayment
      .getListInvoiceApPrepayment(params)
      .then(res => {
        this.dataList = res.data;
        this.totalData = res.totalElements;
      })
      .finally(() => (this.loading.table = false));
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  assignSearch(key, value, operator): string {
    if (key === "branch" && value) {
      this.downloadParam.branch = this.dataBranch.find(item => item.id == value)
        ?.name as string;
      return operator + `branchWarehouse.secureId~${value}`;
    } else if (key === "supplierName" && value) {
      this.downloadParam.supplierName = "";
      return operator + `supplier.secureId~${value}`;
    } else if (key === "invoiceNumber" && value) {
      this.downloadParam.invoiceNumber = "";
      return operator + `documentNumber~${value}`;
    } else if (key === "poNumber" && value) {
      this.downloadParam.poNumber = "";
      return operator + `purchaseOrder.documentNumber~${value}`;
    } else if (key === "invoiceStatus" && value) {
      this.downloadParam.invoiceStatus = "";
      return operator + `status~${value}`;
    } else if (key === "returnNumber" && value) {
      this.downloadParam.returnNumber = "";
      return operator + ``;
    } else if (key === "invoiceDateFrom" && value) {
      this.downloadParam.invoiceDateFrom =
        moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `invoiceDate>=${moment(value)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .format()}`
      );
    } else if (key === "invoiceDateTo" && value) {
      this.downloadParam.invoiceDateTo =
        moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `invoiceDate<=${moment(value)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format()}`
      );
    } else return "";
  }
  clearItems(): void {
    this.form.resetFields();
  }
  CreateNew(): void {
    this.$router.push(
      "/account-payables/invoices/invoices-prepayment-debit-memo/create"
    );
  }
  download() {
    let params = {
      params: `${this.downloadParam.companyName},${this.downloadParam.branch},${this.downloadParam.supplierName},${this.downloadParam.invoiceDateFrom},${this.downloadParam.invoiceDateTo},${this.downloadParam.invoiceNumber},${this.downloadParam.poNumber},${this.downloadParam.returnNumber},${this.downloadParam.invoiceStatus}`,
    } as RequestQueryParamsModel;
    if (this.searchData) {
      params.search = this.searchData;
    }
    invoiceApPrepayment.downloadInvoiceApPrepayment(params).then(res => {
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice-ap-prepayment.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    });
  }
  mounted() {
    this.getBranch("");
    this.getListSupplierName("");
    this.getListPoNumber("");
    this.getListInvoiceNumber("");
    this.getListOfMasterTypeInvoiceStatus();
  }

  getBranch(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  handleClickColumn(record, _objColumnNameValue) {
    this.$router.push(
      "/generaljournal/journal/detail/" + record.journalNumberId
    );
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 0;
    this.currentPage = 1;
    this.findData(true);
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page - 1;
    this.currentPage = response.page;
    this.findData(true);
  }
}
